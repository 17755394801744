import React from 'react';
import {get as _get, maxBy as _maxBy} from "lodash";
import {Header, Icon, Dropdown, Segment, Table, Popup, Checkbox, List} from "semantic-ui-react";

const LiveControlChannelWrapper = () => (
    <div>
        <div className="livecontrol-channel">
            <div className='wrapper-grid flex flex-col'>
                <Header as='h2'>
                    <Icon name='calendar alternate outline' />
                    <Header.Content >Live Control Channels</Header.Content>
                </Header>
                <Segment className='flex' basic>
                    <div className='sc__filter flex w-full'>
                        <div className="flex items-center flex-row-reverse w-full">
                            <div className='transition-container flex w-full'>
                                <Dropdown
                                    placeholder='Type' fluid multiple selection
                                    options={''}
                                />
                                <Dropdown
                                    placeholder='Carousels' fluid multiple selection
                                    options={''}
                                />
                                <Dropdown
                                    placeholder='Country' fluid multiple selection
                                    options={''}
                                />
                                <Dropdown
                                    placeholder='Sport' fluid multiple selection
                                    options={''}
                                />
                                <div className='flex items-center ml-8'>
                                    <Checkbox label='' defaultChecked/>
                                    <div className='highlighted_stared ml-2'>
                                        <div className="sc__event-item__icons colored-bg flex justify-center">
                                            <i className="icon-060-star-1--interface- cursor-pointer"></i>
                                            
                                        </div>
                                    </div>
                                    <span className='ml-2'>Show Favourites</span>
                                </div>
                                <div className='flex items-center ml-8'>
                                    <Checkbox label='' defaultChecked/>
                                    <span className='ml-2'>Show disabled channels</span>
                                </div>
                                <div className='lc_more-button flex items-center flex-1 justify-end'>
                                    <Header as='h4' className='flex items-center'>
                                        <div>
                                            <Icon circular name='settings' />
                                        </div>
                                        <Header.Content>
                                        More{' '}
                                        <Dropdown
                                            inline
                                            header='Adjust time span'
                                            options={''}
                                            defaultValue={''}
                                        />
                                        </Header.Content>
                                    </Header>
                                </div>
                            </div>
                        </div>
                    </div>
                </Segment>
                <div className='button_add flex justify-end'>
                    <Popup content='Add New Channel'
                        trigger={
                            <i className='icon-152-plus-add--core--interface-'></i>   
                        }
                        position='left center'
                    />
                </div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>
                                <Popup content='Select/Deselect all channels '
                                        trigger={<Checkbox label='' defaultChecked/>}
                                        position='top center'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>ID</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Countries</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Competitions</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Preferences</Table.HeaderCell>
                            <Table.HeaderCell>Sports</Table.HeaderCell>
                            <Table.HeaderCell>Clients</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row className="">
                            <Table.Cell textAlign='center'>
                                <Checkbox label='' defaultChecked/>
                            </Table.Cell>
                            <Table.Cell textAlign='center'>2</Table.Cell>
                            <Table.Cell>
                                <a>Betonalfa_Channel</a>
                            </Table.Cell>
                            <Table.Cell>
                                Standard
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                    trigger={<Icon className="cursor-pointer" name='world'/>}
                                    position='bottom center'
                                />
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Popup content='No competitions restrictions'
                                        trigger={<Icon className="cursor-pointer"
                                                        name='checkmark' color='green'/>}
                                        position='top center'
                                />
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <div className='flex justify-center'>
                                    <Popup
                                        trigger={<div className='sc__event-item__icons ml-2'>
                                            <i className='icon-022-basketball--sport-'></i></div>}
                                        content={<>{'Basketball'}</>}
                                        size='tiny'
                                    />
                                    <Popup
                                        trigger={<div className='sc__event-item__icons ml-2'>
                                            <i className='icon-021-soccer--sport-'></i></div>}
                                        content={<>{'Soccer'}</>}
                                        size='tiny'
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell>All Sports</Table.Cell>
                            <Table.Cell>
                                <List celled horizontal>
                                    <List.Item>BCMS</List.Item>
                                    <List.Item>Cybet</List.Item>
                                    <List.Item>HLTMedia</List.Item>
                                    <List.Item>Luckyland</List.Item>
                                </List>
                            </Table.Cell>
                            <Table.Cell className='flex justify-center'>
                                <div className='flex mr-2'>
                                    <Popup content='Launch'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-096-video-play--interface--media-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Edit'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-098-register-signature--core--document-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Schedule'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-010-calendar--core--interface--betting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Live Control'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-009-time--core--interface--betting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Settings'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-027-settings-gear--core--interface--setting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="highlight highlighted_stared">
                            <Table.Cell textAlign='center'>
                                <Popup content='This match is a highlight'
                                    trigger={<div
                                        className="highlighted_action sc-event-highlight sc__event-item__icons colored-bg flex justify-center">
                                        <i className="icon-060-star-1--interface-"></i>
                                    </div>}
                                    position='top center'
                                />
                                <Checkbox label='' defaultChecked/>
                            </Table.Cell>
                            <Table.Cell textAlign='center'>2</Table.Cell>
                            <Table.Cell>
                                <a>Betonalfa_Channel</a>
                            </Table.Cell>
                            <Table.Cell>
                                Standard
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                    trigger={<Icon className="cursor-pointer" name='world'/>}
                                    position='bottom center'
                                />
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Popup content='No competitions restrictions'
                                        trigger={<Icon className="cursor-pointer"
                                                        name='checkmark' color='green'/>}
                                        position='top center'
                                />
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <div className='flex justify-center'>
                                    <Popup
                                        trigger={<div className='sc__event-item__icons ml-2'>
                                            <i className='icon-022-basketball--sport-'></i></div>}
                                        content={<>{'Basketball'}</>}
                                        size='tiny'
                                    />
                                    <Popup
                                        trigger={<div className='sc__event-item__icons ml-2'>
                                            <i className='icon-021-soccer--sport-'></i></div>}
                                        content={<>{'Soccer'}</>}
                                        size='tiny'
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell>All Sports</Table.Cell>
                            <Table.Cell>
                                <List celled horizontal>
                                    <List.Item>BCMS</List.Item>
                                    <List.Item>Cybet</List.Item>
                                    <List.Item>HLTMedia</List.Item>
                                    <List.Item>Luckyland</List.Item>
                                </List>
                            </Table.Cell>
                            <Table.Cell className='flex justify-center'>
                                <div className='flex mr-2'>
                                    <Popup content='Launch'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-096-video-play--interface--media-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Edit'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-098-register-signature--core--document-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Schedule'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-010-calendar--core--interface--betting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Live Control'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-009-time--core--interface--betting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                                <div className='flex mr-2'>
                                    <Popup content='Settings'
                                        trigger={
                                            <div className='button_action'>
                                                <i className="icon-027-settings-gear--core--interface--setting-"></i>
                                            </div>   
                                        }
                                        position='top center'
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

            </div>
        </div>
    </div>
)

export default LiveControlChannelWrapper;
