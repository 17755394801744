import React, {useMemo} from 'react';
import TimelineClock from "./TimelineClock";

const TimelineHours = () => {
    const hoursRange = useMemo(() => {
        const date = new Date(),
            ranges = [];

        for (let minutes = 0; minutes < 24 * 60; minutes = minutes + 60) {
            date.setHours(0);
            date.setMinutes(minutes);
            ranges.push(
                date.toLocaleTimeString('en-US', {
                    hourCycle: 'h23',
                    hour: '2-digit',
                    minute: '2-digit',
                })
            );
        }

        return ranges;
    }, []);

    return (
        <div className='sc__column__timetable flex flex-col w-full relative'>
            <TimelineClock/>
            {hoursRange.map((hour) => (
                <div key={hour} className={'sc__column__time'} style={{height: 60}}>
                    <div className={'sc__column__time__bg flex justify-center'}>
                        <span>{hour}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TimelineHours;
