import React from 'react';
import Logo from "../images/BET_white_logo.png";

const Footer = () => (
    <div>
        <div className={'footer wrapper-grid flex flex-col'}>
            <img src={Logo} alt={''}></img>
            <p>© Sportradar AG, St. Gallen, Switzerland</p>
            <p className={'flex flex-col'}>
                <span>Betradar, the market leading supplier of sports and betting-related data services.</span>
                <span>Support: +41 71 517 72 00</span>
                <span>Website:
                    <a href="/">www.betradar.com</a>
                </span>
            </p>
            <span>BCMS v4.1.1</span>
        </div>
    </div>
);

export default Footer;
