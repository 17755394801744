import React from 'react';
import {Header, Icon, Image} from "semantic-ui-react";

const HeaderGallery = () => (
    <div>
        <Header as='h1'>First Header</Header>
        <Header as='h2'>Second Header</Header>
        <Header as='h3'>Third Header</Header>
        <Header as='h4'>Fourth Header</Header>
        <Header as='h5'>Fifth Header</Header>
        <Header as='h6'>Sixth Header</Header>
        <br/>
        <br/>
        <Header size='huge'>Huge Header</Header>
        <Header size='large'>Large Header</Header>
        <Header size='medium'>Medium Header</Header>
        <Header size='small'>Small Header</Header>
        <Header size='tiny'>Tiny Header</Header>
        <br />
        <br />
        <Header as='h2' icon>
            <Icon name='settings' />
            Account Settings
            <Header.Subheader>
                Manage your account settings and set e-mail preferences.
            </Header.Subheader>
        </Header>
        <br />
        <br />
        <Header as='h2' icon textAlign='center'>
            <Icon name='users' circular />
            <Header.Content>Friends</Header.Content>
        </Header>
        <Image
            centered
            size='large'
            src='/images/semantic-ui/centered-paragraph.png'
        />
        <br />
        <br />
        <Header sub>Price</Header>
        <span>$10.99</span>
        <br />
        <br />
        <Header as='h2'>
            <Image circular src='/images/semantic-ui/patrick.png' /> Patrick
        </Header>
        <br />
        <br />
        <Header as='h2' disabled>
            Disabled Header
        </Header>
    </div>
);

export default HeaderGallery;
