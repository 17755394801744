const formatTwoDigits = (time) => (
    time < 10 ? '0' + time : time
)

export const getTime = (date) => {
    return `${formatTwoDigits(date.getHours())}:${formatTwoDigits(date.getMinutes())}`
};

export const getEventTimeStart = (event) => {
    const date = new Date(event.time_start);

    return getTime(date);
};

export const getEventTimeEnd = (event) => {
    const date = new Date(event.time_end);

    return getTime(date);
};

export const getYearMonthDay = (date) => {
    return date.toISOString().split('T')[0];
};
