import React from 'react';
import {getEventTimeStart} from "../../../../../utils/time";

const EventTime = ({event}) => (
    <div className={'sc__event-item__time'}>
        <span>{getEventTimeStart(event)}</span>
    </div>
);

export default EventTime;
