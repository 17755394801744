import React, {createContext} from "react";

import useFiltersSelected from "./hooks/useFiltersSelected";
import useFiltersOptions from "./hooks/useFiltersOptions";
import useEvents from "./hooks/useEvents";
import useDate from "./hooks/useDate";

const ScheduleContext = createContext();

const ScheduleContextProvider = ({children}) => {
    const [filtersSelected, setFiltersSelected] = useFiltersSelected(),
        [currentDate, setCurrentDate] = useDate(),
        filtersOptions = useFiltersOptions(),
        events = useEvents(currentDate);

    return (
        <ScheduleContext.Provider value={{
            date: {
                currentDate,
                setCurrentDate,
            },
            events: {
                available: events.data.available,
                scheduled: events.data.scheduled,
                loading: events.loading,
                error: events.error,
            },
            filters: {
                filtersSelected,
                setFiltersSelected,
            },
            filtersOptions: {
                data: filtersOptions.data,
                loading: filtersOptions.loading,
                error: filtersOptions.error,
            }
        }}>
            {children}
        </ScheduleContext.Provider>
    );
};

export {ScheduleContext, ScheduleContextProvider};
