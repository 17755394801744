import React from 'react';
import {Table} from "semantic-ui-react";

const Tab3 = () => (
    <div>
        <b>Venue contact person</b>
        <Table celled>
            <Table.Body>
            <Table.Row>
                <Table.Cell>Production company:</Table.Cell>
                <Table.Cell>
                    <a href={`https://lbc-staging.sportradar.ag/stream/production-company/edit/40`}>
                        Sportradar in-house Production
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Name:</Table.Cell>
                <Table.Cell>
                    <a href={`#guy`}>
                        Christian Raab
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Position:</Table.Cell>
                <Table.Cell>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Email:</Table.Cell>
                <Table.Cell>
                    <a href={`#guy`}>
                        christian.raab@unas.tv
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Mobile number:</Table.Cell>
                <Table.Cell>
                    +61414812019
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Skype:</Table.Cell>
                <Table.Cell>
                    christian_unas
                </Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
        <b>Media rights info</b>
        <Table celled>
            <Table.Body>
            <Table.Row>
                <Table.Cell>Licensor:</Table.Cell>
                <Table.Cell>
                    <a href={`https://lbc-staging.sportradar.ag/stream/licensor/edit/208`}>
                        Unas
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Media right manager:</Table.Cell>
                <Table.Cell>
                    <a href={`#guy`}>
                        Florian Bernhard
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Date range:</Table.Cell>
                <Table.Cell>
                    2021-11-01
                    <br></br>
                    2022-07-31
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Geo restrictions:</Table.Cell>
                <Table.Cell>
                    Worldwide
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Match selection:</Table.Cell>
                <Table.Cell>
                    Unlimited use over whole tournament
                    <p></p>
                    <a href={`#guy`}>
                        See license
                    </a>
                </Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
    </div>
);

export default Tab3;
