export const SPORTS_ICON = {
    1: 'icon-021-soccer--sport-',
    2: 'icon-022-basketball--sport-',
    3: 'icon-012-information-circle--core--interface--alert-',
    4: 'icon-226-fire-hot--core--misc-',
    5: 'icon-576-video-stream-off--media-',
    6: 'icon-176-unlink--interface-',
    7: 'icon-353-odds--betting-',
    8: 'icon-361-stop--interface-',
    9: 'icon-163-shuffle--arrow-',
    9: 'icon-429-control-play--core--media-'

};

export const SPORTS_NAME = {
    1: 'Soccer',
    2: 'Basketball',
    3: 'Baseball',
    4: 'Ice Hockey',
    5: 'Tennis',
    6: 'Handball',
    7: 'Floorball',
    8: 'Trotting',
    9: 'Golf',
    10: 'Boxing',
    11: 'Motor sport',
    12: 'Rugby',
    13: 'Aussie rules',
    14: 'Winter sports',
    15: 'Bandy',
    16: 'Football',
    17: 'Cycling',
    18: 'Specials',
    19: 'Snooker',
    20: 'Table tennis',
    21: 'Cricket',
    22: 'Darts',
    23: 'Volleyball',
    24: 'Field Hockey',
    25: 'Pool',
    26: 'Waterpolo',
    27: 'Gaelic sports',
    28: 'Curling',
    29: 'Futsal',
    30: 'Olympics',
    31: 'Badminton',
    32: 'Bowls',
    33: 'Chess',
    34: 'Beach Volley',
    35: 'Netball',
    36: 'Athletics',
    37: 'Squash',
    38: 'Rink Hockey',
    39: 'Lacrosse',
    40: 'Formula 1',
    41: 'Bikes',
    42: 'DTM',
    43: 'Alpine Skiing',
    44: 'Biathlon',
    45: 'Bobsleigh',
    46: 'Cross-Country',
    47: 'Nordic Combined',
    48: 'Ski Jumping',
    49: 'Snowboard',
    50: 'Speed Skating',
    51: 'Luge',
    52: 'Swimming',
    53: 'Finnish Baseball',
    54: 'Softball',
    55: 'Horse Racing',
    56: 'Schwingen',
    57: 'Inline Hockey',
    58: 'Greyhound',
    59: 'Rugby League',
    60: 'Beach Soccer',
    61: 'Pesapallo',
    62: 'Streethockey',
    63: 'World Championship',
    64: 'Rowing',
    65: 'Snowboard AFU',
    66: 'Snowboard ASP',
    67: 'MotoGP',
    68: 'Moto2',
    69: 'Moto3',
    70: 'Nascar Cup Series',
    71: 'Pádel',
    72: 'Canoeing',
    73: 'Horseball',
    74: 'Aquatics',
    75: 'Archery',
    76: 'Equestrian',
    77: 'Fencing',
    78: 'Gymnastics',
    79: 'Judo',
    80: 'Modern Pentathlon',
    81: 'Sailing',
    82: 'Shooting',
    83: 'Taekwondo',
    84: 'Triathlon',
    85: 'Weightlifting',
    86: 'Wrestling',
    87: 'Olympics Youth',
    88: 'Cycling Mountain Bike',
    89: 'Riding',
    90: 'Surfing',
    91: 'Cycling BMX',
    92: 'Canoe Slalom',
    93: 'Rhythmic Gymnastics',
    94: 'Trampoline Gymnastics',
    95: 'Artistic Swimming',
    96: 'Diving',
    97: 'Cycling Track',
    98: 'Beach Tennis',
    99: 'Sumo',
    100: 'Superbike',
    101: 'Rally',
    102: 'Figure Skating',
    103: 'Freestyle Skiing',
    104: 'Skeleton',
    105: 'Short Track Speed Skating',
    106: 'Soccer Mythical',
    107: 'E-sport',
    108: 'World Lottery',
    109: 'ESport Counter-Strike',
    110: 'ESport League of Legends',
    111: 'ESport Dota',
    112: 'ESport StarCraft',
    113: 'ESport Hearthstone',
    114: 'ESport Heroes of the Storm',
    115: 'ESport World of Tanks',
    116: 'Polo',
    117: 'MMA',
    118: 'ESport Call of Duty',
    119: 'ESport Smite',
    120: 'ESport Vainglory',
    121: 'ESport Overwatch',
    122: 'ESport WarCraft III',
    123: 'ESport Crossfire',
    124: 'ESport Halo',
    125: 'ESport Rainbow Six',
    126: 'Sepak Takraw',
    127: 'ESport Street Fighter V',
    128: 'ESport Rocket League',
    129: 'Indy Racing',
    130: 'Basque Pelota',
    131: 'Speedway',
    132: 'ESport Gears of War',
    133: 'ESport Clash Royale',
    134: 'ESport King of Glory',
    135: 'Gaelic Football',
    136: 'Gaelic Hurling',
    137: 'eSoccer',
    138: 'Kabaddi',
    139: 'ESport Quake',
    140: 'ESport PlayerUnknowns Battlegrounds',
    141: 'Cycling Cycle Ball',
    142: 'Formula E',
    143: '7BallRun',
    144: 'Motocross',
    145: 'Sprint Car Racing',
    146: 'Speed Boat Racing',
    147: 'Drag Racing',
    148: 'DEPRECATED Stock Car Racing',
    149: 'Modified Racing',
    150: 'Off Road',
    151: 'Truck & Tractor Pulling',
    152: 'ESport World of Warcraft',
    153: 'eBasketBall',
    154: 'ESport Dragon Ball FighterZ',
    155: 'Basketball 3x3',
    156: 'ESport Tekken',
    157: 'Beach Handball',
    158: 'ESport Arena of Valor',
    159: 'ESport TF2',
    160: 'ESport SSB',
    161: 'ESport Paladins',
    162: 'ESport Artifact',
    163: 'Indoor Soccer',
    164: 'ESport Apex Legends',
    165: 'Indy Lights',
    166: 'ESport Pro Evolution Soccer',
    167: 'ESport Madden NFL',
    168: 'ESport Brawl Stars',
    169: 'Petanque',
    170: 'ESport Fortnite',
    171: 'ESport Card Games',
    172: 'Fishing',
    173: 'Esport Dota Underlords',
    174: 'Esport Teamfight Tactics',
    175: 'Esport Auto Chess',
    176: 'Esport Fighting Games',
    177: 'DEPRECATED sc',
    178: 'ESport Motorsport',
    179: 'Cycling BMX Freestyle',
    180: 'Cycling BMX Racing',
    181: 'Karate',
    182: 'Marathon Swimming',
    183: 'Skateboarding',
    184: 'Sport Climbing',
    185: 'Nascar Camping World Truck',
    186: 'Nascar Xfinity Series',
    187: 'NHRA',
    188: 'Touring Car Racing',
    189: 'Formula 2',
    190: 'Motorcycle Racing',
    191: 'Stock Car Racing',
    192: 'Air Racing',
    193: 'Endurance Racing',
    194: 'ESport Valorant',
    195: 'eIce Hockey',
    196: 'eTennis',
    197: 'eCricket',
    198: 'eVolleyball',
    199: 'ESport Wild Rift'
};