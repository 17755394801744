import React, {useEffect, useState} from "react";
import {Dropdown, Segment} from "semantic-ui-react";
import {get as _get, size as _size} from 'lodash';

import useScheduleContext from "../../context/useScheduleContext";
import classNames from "classnames";

const TimelineFilters = () => {
    const {filters, filtersOptions} = useScheduleContext(),
        [isActiveScroll, setIsActiveScroll] = useState(false),
        [activeFilter, setActiveFilter] = useState(false),
        getOptions = (name) => (
            _get(filtersOptions, `data.${name}`, [])
        );

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setIsActiveScroll(window.scrollY > 100);
        })
    }, []);

    const filterClassName = classNames('sc__filter flex', {
            'filters-scroll': isActiveScroll,
            'filters-active': isActiveScroll && (activeFilter || _size(filters.filtersSelected))
    });

    //@TODO Lukasz - create reusable filter component after label will be defined ?
    return (
        <Segment className='flex justify-end' basic loading={filtersOptions.loading}>
            <div className={filterClassName}>
                <div className="flex items-center flex-row-reverse">
                    <i className="filter-icon icon-244-filter--core--interface- cursor-pointer"
                    onClick={() => {
                        isActiveScroll && setActiveFilter(!activeFilter)
                    }}
                    ></i>
                    <div className='transition-container flex'>
                        <Dropdown
                            name={`sport`}
                            onChange={(e, {name, value}) => filters.setFiltersSelected(name, value)}
                            placeholder='Sports' fluid multiple selection
                            options={getOptions('sport')}
                        />
                        <Dropdown
                            name={`status`}
                            onChange={(e, {name, value}) => filters.setFiltersSelected(name, value)}
                            placeholder='Status' fluid multiple selection
                            options={getOptions('status')}
                        />
                        <Dropdown
                            name={`action`}
                            onChange={(e, {name, value}) => filters.setFiltersSelected(name, value)}
                            placeholder='Action' fluid multiple selection
                            options={getOptions('action')}
                        />
                    </div>
                </div>
            </div>
        </Segment>
    )
};

export default TimelineFilters;
