import React from 'react';

import EventHeader from "./common/EventHeader";
import EventFooter from "./common/EventFooter";

const EventPopup = ({event}) => (
    <div className="col">
        <EventHeader event={event} />
        <EventFooter event={event} />
    </div>
);

export default EventPopup;
