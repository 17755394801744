import React from "react";
import {Segment} from "semantic-ui-react";

import TimelineHours from "./TimelineHours";
import EventsScheduled from "../events/list/EventsScheduled";
import EventsAvailable from "../events/list/EventsAvailable";
import useScheduleContext from "../../context/useScheduleContext";
import TimelineHeader from "./TimelineHeader";

const TimelineLayout = () => {
    const {events} = useScheduleContext();

    return (
        <Segment basic loading={events.loading}>
            <div className={'schedule wrapper-grid flex flex-col'}>
                <TimelineHeader/>
                <div className='sc__scrollable-area flex flex-1'>
                    <TimelineHours/>
                    <div className={'sc__all-columns flex box-border flex-1 w-full h-full box-border'}>
                        <EventsScheduled/>
                        <EventsAvailable/>
                    </div>
                </div>
            </div>
        </Segment>
    )
};

export default TimelineLayout;
