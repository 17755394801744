import React from 'react';

import HeaderClientInfo from "./HeaderClientInfo";
import HeaderLegend from "./HeaderLegend";

const HeaderLayout = () => (
    <>
        <HeaderClientInfo/>
        <HeaderLegend/>
    </>
);

export default HeaderLayout;
