export const eventsData = {
    1:
        {
            scheduled: [
                {
                    id: 1,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:00',
                    time_end: '2021-12-21 10:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 1,
                    action_id: 1,
                },
                {
                    id: 2,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 10:30',
                    time_end: '2021-12-21 12:00',
                    name: 'Vaasan Sport - Karpat Oulu',
                    category: ['Basketball'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 3,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 11:00',
                    time_end: '2021-12-21 11:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 4,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 13:30',
                    time_end: '2021-12-21 14:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 5,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:30',
                    time_end: '2021-12-21 10:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 6,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:30',
                    time_end: '2021-12-21 10:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 7,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 10:30',
                    time_end: '2021-12-21 12:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
            ],
            available: [
                {
                    id: 1,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 05:00',
                    time_end: '2021-12-21 05:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 3,
                },
                {
                    id: 2,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 03:30',
                    time_end: '2021-12-21 04:00',
                    name: 'Vaasan Sport - Karpat Oulu',
                    category: ['Basketball'],
                    sport_id: 2,
                    status_id: 3,
                    action_id: 2,
                },
                {
                    id: 3,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:00',
                    time_end: '2021-12-21 03:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 1,
                },
                {
                    id: 4,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:30',
                    time_end: '2021-12-21 04:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 3,
                    action_id: 1,
                }
            ],
        },
    2:
        {
            scheduled: [
                {
                    id: 1,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 12:00',
                    time_end: '2021-12-21 13:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 1,
                    action_id: 1,
                },
                {
                    id: 2,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 13:30',
                    time_end: '2021-12-21 14:00',
                    name: 'Vaasan Sport - Karpat Oulu',
                    category: ['Basketball'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 3,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 14:00',
                    time_end: '2021-12-21 15:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 4,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 15:30',
                    time_end: '2021-12-21 16:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 5,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 10:30',
                    time_end: '2021-12-21 11:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 6,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:30',
                    time_end: '2021-12-21 10:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 7,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 10:30',
                    time_end: '2021-12-21 12:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
            ],
            available: [
                {
                    id: 1,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 05:00',
                    time_end: '2021-12-21 05:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 3,
                },
                {
                    id: 2,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 03:30',
                    time_end: '2021-12-21 04:00',
                    name: 'Vaasan Sport - Karpat Oulu',
                    category: ['Basketball'],
                    sport_id: 2,
                    status_id: 3,
                    action_id: 2,
                },
                {
                    id: 3,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:00',
                    time_end: '2021-12-21 03:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 1,
                },
                {
                    id: 4,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:30',
                    time_end: '2021-12-21 04:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 3,
                    action_id: 1,
                }
            ],
        },
    3:
        {
            scheduled: [
                {
                    id: 1,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 16:00',
                    time_end: '2021-12-21 17:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 1,
                    action_id: 1,
                },
                {
                    id: 3,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 15:00',
                    time_end: '2021-12-21 16:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 4,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 15:30',
                    time_end: '2021-12-21 16:00',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 5,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 17:30',
                    time_end: '2021-12-21 18:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 6,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 08:30',
                    time_end: '2021-12-21 18:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
            ],
            available: [
                {
                    id: 3,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:00',
                    time_end: '2021-12-21 03:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 1,
                },
                {
                    id: 4,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 02:30',
                    time_end: '2021-12-21 04:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 3,
                    action_id: 1,
                }
            ],
        },
    4:
        {
            scheduled: [
                {
                    id: 1,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:00',
                    time_end: '2021-12-21 20:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 1,
                    action_id: 1,
                },
                {
                    id: 2,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 10:30',
                    time_end: '2021-12-21 20:00',
                    name: 'Vaasan Sport - Karpat Oulu',
                    category: ['Basketball'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 3,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 11:00',
                    time_end: '2021-12-21 20:30',
                    name: 'Panama - El Salvador',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 2,
                    action_id: 2,
                },
                {
                    id: 4,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 13:30',
                    time_end: '2021-12-21 20:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                },
                {
                    id: 5,
                    event_type: 1, // scheduled
                    type: 'LIVE',
                    time_start: '2021-12-21 09:30',
                    time_end: '2021-12-21 20:30',
                    name: 'Saipa Lappeenranta - Kalpa Hockey',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 2,
                    status_id: 2,
                    action_id: 3,
                }
            ],
            available: [
                {
                    id: 1,
                    event_type: 2, // available
                    type: 'LIVE',
                    time_start: '2021-12-21 05:00',
                    time_end: '2021-12-21 05:30',
                    name: 'Ninbo Rockets - Shandong Heroes ',
                    category: ['Russia', 'NFL', 'ABC'],
                    sport_id: 1,
                    status_id: 3,
                    action_id: 3,
                }
            ],
        },
};
