import React, {useRef, useState} from 'react';
import {maxBy as _maxBy, get as _get} from "lodash";

import Event from "../box/Event";
import {addDisplayingData} from "./utils/schedule";

const SCROLL_SLOW_RATE = 5;

const EventsList = ({events}) => {
    const [dragStart, setDragStart] = useState(0),
        [dragPosition, setDragPosition] = useState(0),
        scrollTo = useRef(null),
        // Get event with max column value, and get this value to calculate number of columns
        columnNumbers = _get(_maxBy(events, (event) => event.column), 'column', 1);

    const scroll = (scrollOffset) => {
            scrollTo.current.scrollLeft += scrollOffset;
        },
        onDrag = (event) => {
            setDragPosition(event.clientX);

            if (event.clientX && dragPosition !== event.clientX) {
                scroll((event.clientX - dragStart) / SCROLL_SLOW_RATE);
            }
        },
        onDragStart = (event) => {
            setDragStart(event.clientX);
            event.dataTransfer.setDragImage(document.getElementsByClassName('empty')[0], 0, 0);
        };

    return (
        <div
            draggable
            ref={scrollTo}
            className={'sc__column sc__scrollable-content flex flex-col box-border h-full'}
            onDrag={onDrag}
            onDragStart={onDragStart}
        >
            <div className={'sc__column__content flex flex-1'}>
                <div className={'sc__column__events flex flex-1 relative'}>
                    {events.map((event) => (
                        <Event key={event.id} event={addDisplayingData(event, columnNumbers)}/>
                    ))}
                </div>
            </div>
            <div className="empty"></div>
        </div>
    );
}

export default EventsList;