import {useContext} from "react";
import {ScheduleContext} from "./ScheduleContext";

const useScheduleContext = () => {
    const context = useContext(ScheduleContext);

    if (context === undefined) {
        throw new Error("useScheduleContext was used outside of its Provider");
    }

    return context;
};

export default useScheduleContext;
