import React, {useState} from 'react';
import {
    Button, Menu, Modal, Grid, Table, Header, Icon, Checkbox, Dropdown, Form, Popup, Radio, Input
} from 'semantic-ui-react';
import {merge as _merge} from "lodash";

import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";

// @TODO Lukasz replace by Modal component

const options = [
    {key: '.com', text: 's', value: '.com'},
    {key: '.net', text: 'ms', value: '.net'},
]

const ModalLayout = ({event, open, setOpen}) => {
    const [activeItem, setActiveItem] = useState('tab1');
    const [volume, setVolume] = useState({
        'duration': 100,
    });
    const handleChangeVolume = (e, {name, value}) => {
        const newVolume = _merge(volume, {[name]: +value});
        setVolume(newVolume);
    };

    const renderTab = () => {
        switch (activeItem) {
            case 'tab1':
                return <Tab1 event={event}/>;
            case 'tab2':
                return <Tab2 event={event}/>;
            case 'tab3':
                return <Tab3 event={event}/>;
            default:
                return null;
        }
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size={'fullscreen'}
        >
            <Modal.Header>
                {event.name}
                <div className="sc__event-item__meta flex items-center">
                    <h5>{event.id}</h5>
                    {event.category.map((cat) => (<span key={cat}>{cat}</span>))}
                </div>
            </Modal.Header>
            <Modal.Content image>
                <Modal.Description className="w-full">
                    <Grid className="w-full modal_grid">
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Header>
                                    Information
                                </Header>
                                <div>
                                    <Menu pointing>
                                        <Menu.Item
                                            name="Match"
                                            active={activeItem === 'tab1'}
                                            onClick={() => setActiveItem('tab1')}
                                            className={"justify-center"}
                                        />
                                        <Menu.Item
                                            name='Stream'
                                            active={activeItem === 'tab2'}
                                            onClick={() => setActiveItem('tab2')}
                                            className={"justify-center"}
                                        />
                                        <Menu.Item
                                            name='General'
                                            active={activeItem === 'tab3'}
                                            onClick={() => setActiveItem('tab3')}
                                            className={"justify-center"}
                                        />
                                    </Menu>
                                    {renderTab()}
                                </div>

                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Header>
                                    Channels
                                </Header>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <Popup content='Select/Deselect all channels '
                                                       trigger={<Checkbox label='' defaultChecked/>}
                                                       position='top center'
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Countries</Table.HeaderCell>
                                            <Table.HeaderCell>Sports</Table.HeaderCell>
                                            <Table.HeaderCell>Competitions</Table.HeaderCell>
                                            <Table.HeaderCell>LMT</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row className="table-active">
                                            <Table.Cell>
                                                <Checkbox label='' defaultChecked/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>{event.id}</Table.Cell>
                                            <Table.Cell>Mixed Sports Channel Africa / Soccer only</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                                       trigger={<Icon className="cursor-pointer" name='world'/>}
                                                       position='bottom center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell>All Sports</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='No competitions restrictions'
                                                       trigger={<Icon className="cursor-pointer"
                                                                      name='checkmark' color='green'/>}
                                                       position='top center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' className="flex justify-center">
                                                <Popup content='No LMT allowed'
                                                       trigger={<Icon className="cursor-pointer"
                                                            name='eye slash' color=''/>}
                                                       position='top center'
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Checkbox label='' defaultChecked/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>{event.id}</Table.Cell>
                                            <Table.Cell>Mixed Sports Channel Africa / Soccer only</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                                       trigger={<Icon className="cursor-pointer" name='world'/>}
                                                       position='bottom center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell>All except Handball, Esoccer</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Icon className="cursor-pointer" name='checkmark' color='green'/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' className="flex justify-center">
                                                <Popup content='LMT allowed'
                                                        trigger={<Icon className="cursor-pointer"
                                                                name='eye' color=''/>}
                                                        position='top center'
                                                    />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="highlight">
                                            <Table.Cell>
                                                <Popup content='This match is a highlight'
                                                    trigger={<div
                                                        className="highlighted_action sc-event-highlight sc__event-item__icons colored-bg flex justify-center">
                                                        <i className="icon-226-fire-hot--core--misc- cursor-pointer"></i>
                                                    </div>}
                                                    position='top center'
                                                />
                                                <Checkbox label='' defaultChecked/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>{event.id}</Table.Cell>
                                            <Table.Cell>Mixed Sports Channel Africa / Soccer only</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                                       trigger={<Icon className="cursor-pointer" name='world'/>}
                                                       position='bottom center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell>All except Handball, Esoccer</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Icon className="cursor-pointer" name='close' color='red'/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' className="flex justify-center">
                                                <Popup content='LMT allowed'
                                                        trigger={<Icon className="cursor-pointer"
                                                                name='eye' color=''/>}
                                                        position='top center'
                                                    />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="highlight">
                                            <Table.Cell>
                                                <Popup content='This match is a highlight'
                                                    trigger={<div
                                                        className="highlighted_action sc-event-highlight sc__event-item__icons colored-bg flex justify-center">
                                                        <i className="icon-226-fire-hot--core--misc- cursor-pointer"></i>
                                                    </div>}
                                                    position='top center'
                                                />
                                                <Checkbox label='' defaultChecked/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>{event.id}</Table.Cell>
                                            <Table.Cell>Mixed Sports Channel Africa / Soccer only</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='DE, US, EN, ESP, POL, FIN, UK, BL, CRO'
                                                       trigger={<Icon className="cursor-pointer" name='world'/>}
                                                       position='bottom center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell>All except Handball, Esoccer</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Icon className="cursor-pointer" name='close' color='red'/>
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                <Popup content='LMT not allowed'
                                                        trigger={<Icon className="cursor-pointer"
                                                                name='eye slash' color=''/>}
                                                        position='top center'
                                                    />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <div className="sticky-column my-8 w-full mb-0">
                                    <div className="p-4 box-border">
                                        <div className="flex item-center text-sm mb-4">
                                            <Popup
                                                content='Applies only when you add or remove an event or when you modify the "Alternative" property'
                                                trigger={<Checkbox label='' defaultChecked/>}
                                                position='top center'
                                            />
                                            <span>Include this schedule update in the email sent to clients who subscribed to the 'Schedule updates'</span>
                                        </div>
                                        <div>
                                            <Button content='Save' primary/>
                                            <Button content='Remove from schedule' secondary/>
                                            <Button basic>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Header>
                                    Event Properties
                                </Header>
                                <Table celled>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Match display solution:</Table.Cell>
                                            <Table.Cell>
                                                <Menu compact>
                                                    <Dropdown text='Streaming' simple item/>
                                                </Menu>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Event start time:</Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    <Form.Field>
                                                        <input type='text' value={event.time_start}/>
                                                    </Form.Field>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Approx. end time:</Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    <Form.Field>
                                                        <input type='text' value={event.time_end}/>
                                                    </Form.Field>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Status:</Table.Cell>
                                            <Table.Cell>
                                                <Menu compact>
                                                    <Dropdown text='-- Select --' simple item/>
                                                </Menu>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Message:</Table.Cell>
                                            <Table.Cell>
                                                <Menu compact>
                                                    <Dropdown text='-- Select --' simple item/>
                                                </Menu>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell className="flex items-center justify-between">
                                                Show cat name
                                                <Popup content='Show category name in announcement screen'
                                                       trigger={<i
                                                           className="icon-012-information-circle--core--interface--alert- sr-icon sr-icon-hover"></i>}
                                                       position='top center'
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio toggle/>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Left Volume</Table.Cell>
                                            <Table.Cell>
                                                <div className="flex">
                                                    <Form.Input
                                                        min={100}
                                                        max={2000}
                                                        name='duration'
                                                        onChange={handleChangeVolume}
                                                        step={100}
                                                        type='range'
                                                        rating={volume.duration}
                                                        className="volume_track flex width-70-per"
                                                    />
                                                    <Form className="volume_form flex width-30-per items-center justify-center">
                                                        <Form.Field>
                                                            <input type='text' value="50%"/>
                                                        </Form.Field>
                                                    </Form>
                                                </div>
                                                <span className="text-sm">*same as stream config</span>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Right Volume</Table.Cell>
                                            <Table.Cell>
                                                <div className="flex">
                                                    <Form.Input
                                                        min={100}
                                                        max={2000}
                                                        name='duration'
                                                        onChange={handleChangeVolume}
                                                        step={100}
                                                        type='range'
                                                        rating={volume.duration}
                                                        className="volume_track flex width-70-per"
                                                    />
                                                    <Form className="volume_form flex width-30-per items-center justify-center">
                                                        <Form.Field>
                                                            <input type='text' value="50%"/>
                                                        </Form.Field>
                                                    </Form>
                                                </div>
                                                <span className="text-sm">*same as stream config</span>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Alternative event
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio toggle/>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Highlight of the day
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio toggle/>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Live stats delay:
                                            </Table.Cell>
                                            <Table.Cell className='gray-input listbox-w-input'>
                                                <Input
                                                    label={<Dropdown defaultValue='.com' options={options}/>}
                                                    labelPosition='right'
                                                    placeholder='Find domain'
                                                    value='5'
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Created on:
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="flex items-center"><i
                                                    className="icon-010-calendar--core--interface--betting- sr-icon mr-2 text-lg"></i>2022-02-02
                                                </div>
                                                <div className="flex items-center"><i
                                                    className="icon-009-time--core--interface--betting- sr-icon mr-2 text-lg"></i>09:35:56
                                                </div>
                                                <div className="flex items-center"><i
                                                    className="icon-029-user-male--core--interface--user- sr-icon mr-2 text-lg"></i>by
                                                    Andreas Liftinger
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='sticky-bottom-actions'>
                <Button basic
                        content="Close"
                        labelPosition='right'
                        icon='close'
                        onClick={() => setOpen(false)}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ModalLayout;
