import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {size as _size} from "lodash";
import classNames from "classnames";

import DateControl from "./DateControl/DateControl";
import ActionLegend from "./HeaderLegend/ActionLegend";

import useScheduleContext from "../../context/useScheduleContext";

const HeaderLegend = () => {
    const {filters} = useScheduleContext(),
        legendClassNames = classNames('sc__subheader flex wrapper-grid flex-col',
            // @TODO Babsi
            {'sticky-classname-xyz': _size(filters.filtersSelected)}
        );

    return (
        <div className={legendClassNames}>
            <div className="flex justify-between items-center">
                <DateControl/>
                <ActionLegend/>
            </div>
        </div>
    );
};

export default HeaderLegend;
