import React from 'react';

const HeaderClientInfo = () => (
    <div className="sc__title wrapper-grid">
        <h1>Mixed Channel OpapSports</h1>
        <div className="sc__client">
            <span className="sr-gray-800">From Clients in use:</span>
            <a href={`#something`}>BCMS</a>
            <span>,</span>
            <a href={`#client`}>OpapSports</a>
        </div>
    </div>
);

export default HeaderClientInfo;
