import {cloneDeep as _cloneDeep, each as _each, filter as _filter, isEmpty as _isEmpty} from "lodash";
import {FILTER_CONFIGURATION} from "../../../../const/filterConfiguration";

export const getEventsListByFilters = (events, filtersSelected) => (
    _cloneDeep(_filter(events, function (event) {
        let selectedFilter = true;

        _each(FILTER_CONFIGURATION, (filterConf) => {
            if (!_isEmpty(filtersSelected[filterConf.filterName]) && selectedFilter) {
                if (!filtersSelected[filterConf.filterName].includes(event[filterConf.eventPath])) {
                    selectedFilter = false;

                    return false;
                }
            }
        });

        return selectedFilter;
    }))
);
