import {useEffect, useState} from "react";

import {sortBy as _sortBy} from "lodash";
import {eventsData} from "./__mocks__/eventsData";

const orderSchedule = (events) => {
    /**
     * Order by time start and then by length of event
     */
    const orderTimeLength = _sortBy(events, [
        (event) => (new Date(event.time_start).getTime()),
        (event) => (new Date(event.time_start).getTime() - new Date(event.time_end).getTime())
    ]);

    const ColumnMap = new Map();

    const getEventColumn = (event) => {
        let columnRet = 1;
        const eventTimeEnd = new Date(event.time_end).getTime();

        /**
         * Set 1st element on the 1st column
         */
        if (0 === ColumnMap.size) {
            ColumnMap.set(1, eventTimeEnd);

            return 1;
        }

        let columnUpdated = false;
        const eventTimeStart = new Date(event.time_start).getTime();

        /**
         * Go through columns and check if:
         * date start of event is AFTER
         * time_end of last event in checked column
         */
        ColumnMap.forEach((lastTimeEnd, column) => {
            if (!columnUpdated && eventTimeStart >= lastTimeEnd) {
                ColumnMap.set(column, eventTimeEnd);

                columnRet = column;
                columnUpdated = true;
            }
        });

        /**
         * Set time end for column configuration for the future checking process
         */
        if (!columnUpdated) {
            ColumnMap.set(ColumnMap.size + 1, eventTimeEnd);
            columnRet = ColumnMap.size;
        }

        return columnRet;
    }

    orderTimeLength.forEach((event) => {
        /**
         * Assign column to event
         */
        event.column = getEventColumn(event);
    });

    return orderTimeLength;
}


const useEvents = (currentDate) => {
    const [data, setData] = useState({
            scheduled: [], available: [],
        });
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (
            async function () {
                try {
                    /**
                     * @TODO Lukasz
                     * Replace by valid GraphQL request/response
                     */
                    const eventsDataKeys = Object.keys(eventsData);
                    const eventRandomKey = 1;
                    const events = eventsData[eventRandomKey];

                    setLoading(true);

                    setTimeout(function () {
                        setData({
                            scheduled: orderSchedule(events.scheduled),
                            available: orderSchedule(events.available)
                        });
                        setLoading(false);
                    }, 1);

                } catch (err) {
                    setError(err)
                }
            }
        )()
    }, [currentDate.getDate()])

    return {data, error, loading}
}

export default useEvents;
