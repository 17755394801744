import React from 'react';
import HeaderLayout from "../schedule/components/header/HeaderLayout";
import TimelineLayout from "../schedule/components/timeline/TimelineLayout";
import {Popup, Segment, Grid, Divider, Header, Icon, Button, Card, Menu, Dropdown, List, Message} from "semantic-ui-react";
import {ScheduleContextProvider} from "../schedule/context/ScheduleContext";
import useScheduleContext from "../schedule/context/useScheduleContext";
import Event from "../schedule/components/events/box/Event";
import {addDisplayingData} from "../schedule/components/events/list/utils/schedule";
import {get as _get, maxBy as _maxBy} from "lodash";
import EventHeader from "../schedule/components/events/box/common/EventHeader";
import HeaderClientInfo from "../schedule/components/header/HeaderClientInfo";
import EventFooter from "../schedule/components/events/box/common/EventFooter";

const LiveControlLayoutWrapper = () => (
    <ScheduleContextProvider>
        <LiveControlLayout/>
    </ScheduleContextProvider>
)

const LiveControlLayout = () => {
    const events = [
        {
            id: 1,
            event_type: 1, // scheduled
            type: 'LIVE',
            time_start: '2021-12-21 09:00',
            time_end: '2021-12-21 10:30',
            name: 'Ninbo Rockets - Shandong Heroes ',
            category: ['Russia', 'NFL', 'ABC'],
            sport_id: 1,
            status_id: 1,
            action_id: 1,
        },
        {
            id: 2,
            event_type: 1, // scheduled
            type: 'LIVE',
            time_start: '2021-12-21 10:30',
            time_end: '2021-12-21 12:00',
            name: 'Vaasan Sport - Karpat Oulu',
            category: ['Basketball'],
            sport_id: 2,
            status_id: 2,
            action_id: 2,
        },
        {
            id: 3,
            event_type: 1, // scheduled
            type: 'LIVE',
            time_start: '2021-12-21 11:00',
            time_end: '2021-12-21 11:30',
            name: 'Panama - El Salvador',
            category: ['Russia', 'NFL', 'ABC'],
            sport_id: 1,
            status_id: 2,
            action_id: 2,
        },
    ];

    return (
        <div className="livecontrol">
            <HeaderClientInfo/>
            <div className='wrapper-grid flex justify-end'>
                <Popup
                    trigger={
                    <Button basic icon className='lc__header__buttons'>
                        <Icon name='file text' />
                        <span>Log</span>
                    </Button>}
                    content={<span>Lorem ipsum usefull text</span>}
                    size='tiny'
                    position='top right'
                />
                <Popup
                    trigger={
                    <Button basic icon className='lc__header__buttons'>
                        <Icon name='calendar alternate outline' />
                        <span>Edit Schedule</span>
                    </Button>}
                    content={<span>Lorem ipsum usefull text</span>}
                    size='tiny'
                    position='top right'
                />
                <Popup
                    trigger={
                    <Button basic icon className='lc__header__buttons'>
                        <Icon name='video camera' />
                        <span>Preview</span>
                    </Button>}
                    content={<span>Lorem ipsum usefull text</span>}
                    size='tiny'
                    position='top right'
                />
                <Popup
                    trigger={
                    <Button basic icon className='lc__header__buttons'>
                        <Icon name='refresh' />
                        <span>Refresh hosted solutions</span>
                    </Button>}
                    content={<span>Lorem ipsum usefull text</span>}
                    size='tiny'
                    position='top right'
                />
                <Popup
                    trigger={
                    <Button basic icon className='lc__header__buttons'>
                        <Icon name='refresh' />
                        <span>Refresh scoreboard</span>
                    </Button>}
                    content={<span>Lorem ipsum usefull text</span>}
                    size='tiny'
                    position='top right'
                />
            </div>

            <div className="livecontrol__body flex">
                <div className="sc__lc__static wrapper-grid flex flex-1 flex-col">
                    <div className='flex flex-col'>
                        <Message
                            info
                            size='small'
                            icon='info circle'
                            header='Customer XYZ'
                            content='is currently taking some actions'
                        />
                        <span>This channel is controlled by:</span>
                        <Card.Group className='w-full'>
                            <Card>
                                <Card.Content className="flex">
                                <div>
                                    <Card.Header>Steve Sanders</Card.Header>
                                    <Card.Meta>No restrictions</Card.Meta>
                                </div>
                                <div className='flex flex-1 justify-end'>
                                    <Icon circular inverted name='user' floated='right'/>
                                </div>
                                </Card.Content>
                                <Card.Content extra>
                                    <Button className='w-full' icon labelPosition='left' size='large' color='red'>
                                        <Icon name='unlock' />
                                        Take Control
                                    </Button>
                                    <Button className='display-none w-full'primary size='large'>
                                        Set Action
                                    </Button>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </div>
                    <div className='flex flex-col'>
                        <span>Select between following actions:</span>
                        <Card.Group className='w-full'>
                            <Card>
                                <Card.Content className="flex">
                                    <Button className='w-full' primary icon labelPosition='left' size='large'>
                                        <Icon name='retweet' />
                                        Announcement Loop
                                    </Button>
                                </Card.Content>
                                <Card.Content extra>
                                    <List bulleted>
                                        <List.Item>Channel trailer</List.Item>
                                        <List.Item>Game announcement</List.Item>
                                        <List.Item>Marketing video</List.Item>
                                        <List.Item>Highlights of the day</List.Item>
                                    </List>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                        <Divider horizontal>Or</Divider>
                        <Button className='w-full lc__button' default size='large'>
                            Game announcement
                        </Button>
                        <Button className='w-full lc__button' default size='large'>
                            Highlights of the day
                        </Button>
                        <Button className='w-full lc__button' default size='large'>
                            World Numbers Service
                        </Button>
                        <Button className='w-full lc__button' default size='large'>
                            Channel trailer
                        </Button>
                        <Button className='w-full lc__button' default size='large'>
                            Marketing video
                        </Button>
                        <Divider horizontal>Or</Divider>
                        <Button className='lc__button' animated primary size='large'>
                            <Button.Content visible>Enter live-match</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                        <Button className='lc__button' animated primary size='large'>
                            <Button.Content visible>Enter match-break show</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                        <Button className='lc__button' animated primary size='large'>
                            <Button.Content visible>Enter post-match</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                    </div>
                </div>
                <div className="sc__scrollable-area flex flex-1 flex-col">
                    <div className="sc-timeline-marker z-02 flex items-center justify-center h-full" style={{left: 20}}>
                        <span className="sc-tooltip flex">09:40</span>
                    </div>
                    <div className="sc__column__timetable livecontrol__timetable__header-bg flex w-full relative z-01 pl-4">
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                            <div className="sc__column__time__bg flex justify-center">
                                <span>00:00</span>
                            </div>
                        </div>
                    </div>
                    <div className="sc__column__timetable livecontrol__timetable__bg flex w-full absolute h-full pl-4">
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                        <div className="sc__column__time box-border" style={{width: 280}}>
                        </div>
                    </div>
                    <div className="sc__column__events flex-col z-01 pl-4">
                        {events.map((event) => (
                            <div className="row">
                                <div className="sc__event-item sr-gray-700 flex box-border flex-1 max-w-full w-full h-full scheduled scheduled" style={{left: 0, width: 320,}}>
                                    <div className="flex flex-col flex-1">
                                        <EventHeader event={event}/>
                                        <EventFooter event={event}/>
                                    </div>
                                    <div className="sc__event-item__buttons flex flex-col">
                                        <button className="justify-end flex-1">
                                            <Popup
                                                trigger={<i className="icon-163-shuffle--arrow-"></i>}
                                                content={<span>Switch streams</span>}
                                                size='tiny'
                                                position='right center'
                                            />
                                        </button>
                                        <button className="justify-end flex-1">
                                            <Popup
                                                trigger={<i className="icon-429-control-play--core--media-"></i>}
                                                content={<span>Play stream</span>}
                                                size='tiny'
                                                position='right center'
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='livecontrol__footer flex flex-col wrapper-grid'>
            <Grid columns={3} stackable>
                <Grid.Column className='flex'>
                <Segment raised className='flex flex-col flex-1 h-full'>
                    <h3>Breaks</h3>
                    <div className='flex mt-4'>
                        <Button className='flex-1' icon labelPosition='left'>
                            <Icon name='pause' />
                            Stream
                        </Button>
                        <Button className='flex-1' icon labelPosition='left'>
                            <Icon name='pause' />
                            Live Odd
                        </Button>
                        <Button className='flex-1' icon labelPosition='left'>
                            <Icon name='pause' />
                            Live Stat
                        </Button>
                    </div>
                </Segment>
                </Grid.Column>
                <Grid.Column className='flex'>
                <Segment raised className='flex flex-col flex-1 h-full'>
                    <h3>Crawler</h3>
                    <div className='flex mt-4'>
                        <Dropdown
                            className='lc__dropdown flex-1'
                            placeholder='Select the notification'
                            selection
                        />
                        <Dropdown
                            placeholder='Number of iterations'
                            selection
                        />
                    </div>
                    <div className='flex mt-4'>
                        <Button primary>
                            Show
                        </Button>
                        <Button color='red' basic>
                            Chancel last crawler
                        </Button>
                    </div>
                    <div className='flex mt-4'>
                        <h4>Automatic marketing message</h4>
                    </div>
                    <div className='flex mt-4'>
                        <Dropdown
                            className='lc__dropdown flex-1'
                            placeholder='Every 30 seconds'
                            selection
                        />
                        <Button>
                            OK
                        </Button>
                    </div>
                </Segment>
                </Grid.Column>
                <Grid.Column className='flex'>
                <Segment raised className='flex flex-col flex-1 h-full'>
                    <h3>Match-break switch</h3>
                    <div className='flex mt-4'>
                        <h4>Select the match which will be displayed during the next match-break:</h4>
                    </div>
                    <div className='flex mt-4'>
                        <Dropdown
                            className='lc__dropdown flex-1'
                            placeholder='Roger Federer vs Rafael Nadal'
                            selection
                        />
                        <Button>
                            OK
                        </Button>
                    </div>
                </Segment>
                </Grid.Column>
            </Grid>
                <div className='fixed-bottom'>
                    <Message icon>
                        <Icon name='info circle'/>
                        <Message.Content>
                        <Message.Header>Match break show: LAAKSONEN, HENRI : GASQUET, RICHARD (32998491)</Message.Header>
                        The event is currently in the <b>match break show</b> stage and is running the following elements in a loop:
                            <List bulleted>
                                <List.Item>Match-break stats</List.Item>
                            </List>
                        
                        </Message.Content>
                    </Message>
                </div>
                <div className='display-none'>
                    <Message negative compact onDismiss={'this.handleDismiss'} className='info-message' style={{bottom: 100}}>
                        <Message.Content>
                        <Message.Header>Event ending!</Message.Header>
                        The current live event <b>LAAKSONEN, HENRI : GASQUET, RICHARD (32998491)</b> is going to end in 1 minute!                  
                        </Message.Content>
                    </Message>
                    <Message warning compact onDismiss={'this.handleDismiss'} className='info-message' style={{bottom: 180}}>
                        <Message.Content>
                        <Message.Header>Event ending!</Message.Header>
                        The current live event <b>LAAKSONEN, HENRI : GASQUET, RICHARD (32998491)</b> is going to end in 5 minutes!                  
                        </Message.Content>
                    </Message>
                </div>
            </div>
        </div>
    );
}

export default LiveControlLayoutWrapper;
