import React from 'react';
import {Table, Icon, Button} from "semantic-ui-react";

const Tab1 = ({event}) => (
    <div>
        <Table celled>
            <Table.Body>
            <Table.Row>
                <Table.Cell>Start time:</Table.Cell>
                <Table.Cell>{event.time_start}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Approx. end time:</Table.Cell>
                <Table.Cell>{event.time_end}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Status:</Table.Cell>
                <Table.Cell negative className="live-event">
                    <Icon name='circle' />
                    {event.type}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Stadium:</Table.Cell>
                <Table.Cell>Estadio Olimpico Metropolitano (San Pedro Sula)</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Scout:</Table.Cell>
                <Table.Cell positive>
                    <Icon name='checkmark' color='green' />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Live Odds:</Table.Cell>
                <Table.Cell positive>
                    <Icon name='checkmark' color='green' />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>1X2 odds:</Table.Cell>
                <Table.Cell>1:2.489 - X:3.099 - 2:2.980</Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
        <div>
            <Button primary icon labelPosition='left'>
                <Icon name='play' />
                Play
            </Button>
        </div>
    </div>
);

export default Tab1;
