import React from 'react';

const ScheduleBackup = () => (
    <div>
        <div className={'schedule wrapper-grid flex flex-col'}>
            <div className={'sc__fixed-header flex flex-col'}>
                <div className={'sc__settings'}>
                </div>
                <div className={'sc__column__title flex'}>
                    <div className={'sc__column flex box-border justify-center'}>
                        <div className={'sc__fixed-header__title'}>
                            <h3>Scheduled events</h3>
                        </div>
                    </div>
                    <div className={'sc__column flex box-border justify-center'}>
                        <div className={'sc__fixed-header__title'}>
                            <h3>Available matches</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sc__scrollable-area flex flex-1'>
                <div className={'sc__column flex flex-col box-border'}>
                    <div className={'sc__column__content flex flex-1'}>
                        <div className='sc__column__timetable flex flex-col'>
                            <div className={'sc__column__time'} style={{height: 60}}>
                                <span>00:00</span>
                            </div>
                            <div className={'sc__column__time'} style={{height: 60}}>
                                <span>01:00</span>
                            </div>
                            <div className={'sc__column__time'} style={{height: 60}}>
                                <span>02:00</span>
                            </div>
                        </div>
                        <div className={'sc__column__events flex flex-1'}>
                            <div className={'col flex flex-1 relative'}>
                                <div className={'sc__event-item flex flex-col box-border flex-1 max-w-full w-full'} style={{top: 120}}>
                                    <div className={'sc__event-item__top-row flex'}>
                                        <div className={'sc__event-item__time sr-gray-700'}>
                                            <span>00:30</span>
                                        </div>
                                        <div className={'sc__event-item__status sr-red-700'}>
                                            <i className={''}></i>
                                            <span>LIVE</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'icon-021--soccer'}></i>
                                        </div>
                                    </div>
                                    <h4>Vaasan Sport - Karpat Oulu</h4>
                                    <div className={'sc__event-item__bottom-row'}>
                                        <div className={'sc__event-item__meta sr-gray-700'}>
                                            <i className={'info-icon'}></i>
                                            <span>Ice Hockey</span>
                                            <span>Russia</span>
                                            <span>MHL</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'no-video'}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col flex flex-1 relative'}>
                                <div className={'sc__event-item flex flex-col box-border flex-1 max-w-full w-full'} style={{top: 80}}>
                                    <div className={'sc__event-item__top-row flex'}>
                                        <div className={'sc__event-item__time sr-gray-700'}>
                                            <span>00:30</span>
                                        </div>
                                        <div className={'sc__event-item__status sr-red-700'}>
                                            <i className={''}></i>
                                            <span>LIVE</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'icon-021--soccer'}></i>
                                        </div>
                                    </div>
                                    <h4>Vaasan Sport - Karpat Oulu</h4>
                                    <div className={'sc__event-item__bottom-row'}>
                                        <div className={'sc__event-item__meta sr-gray-700'}>
                                            <i className={'info-icon'}></i>
                                            <span>Ice Hockey</span>
                                            <span>Russia</span>
                                            <span>MHL</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'no-video'}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'sc__column flex flex-col box-border'}>
                    <div className={'sc__column__content flex flex-1'}>
                        <div className={'sc__column__events flex flex-1'}>
                            <div className={'col flex flex-1 relative'}>
                                <div className={'sc__event-item flex flex-col box-border flex-1 max-w-full w-full'} style={{top: 120}}>
                                    <div className={'sc__event-item__top-row flex'}>
                                        <div className={'sc__event-item__time sr-gray-700'}>
                                            <span>00:30</span>
                                        </div>
                                        <div className={'sc__event-item__status sr-red-700'}>
                                            <i className={''}></i>
                                            <span>LIVE</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'icon-021--soccer'}></i>
                                        </div>
                                    </div>
                                    <h4>Vaasan Sport - Karpat Oulu</h4>
                                    <div className={'sc__event-item__bottom-row'}>
                                        <div className={'sc__event-item__meta sr-gray-700'}>
                                            <i className={'info-icon'}></i>
                                            <span>Ice Hockey</span>
                                            <span>Russia</span>
                                            <span>MHL</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'no-video'}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col flex flex-1 relative'}>
                                <div className={'sc__event-item flex flex-col box-border flex-1 max-w-full w-full'} style={{top: 80}}>
                                    <div className={'sc__event-item__top-row flex'}>
                                        <div className={'sc__event-item__time sr-gray-700'}>
                                            <span>00:30</span>
                                        </div>
                                        <div className={'sc__event-item__status sr-red-700'}>
                                            <i className={''}></i>
                                            <span>LIVE</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'icon-021--soccer'}></i>
                                        </div>
                                    </div>
                                    <h4>Vaasan Sport - Karpat Oulu</h4>
                                    <div className={'sc__event-item__bottom-row'}>
                                        <div className={'sc__event-item__meta sr-gray-700'}>
                                            <i className={'info-icon'}></i>
                                            <span>Ice Hockey</span>
                                            <span>Russia</span>
                                            <span>MHL</span>
                                        </div>
                                        <div className={'sc__event-item__icons'}>
                                            <i className={'no-video'}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ScheduleBackup;
