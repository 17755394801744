import React from "react";
import {range} from "lodash";
import classNames from "classnames";

import useScheduleContext from "../../../context/useScheduleContext";

const NextDays = () => {
    const {date} = useScheduleContext(),
        days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return (
        <div className={'datepicker__week flex sr-gray-600 font-bold'}>
            {range(6).map((addDay) => {
                const nextDay = new Date();

                nextDay.setDate(new Date().getDate() + addDay);

                const isToday = (date.currentDate.getDate() === nextDay.getDate());

                return (
                    <span
                        key={addDay}
                        className={classNames('mr-8', {'active sr-gray-1400': isToday})}
                        onClick={() => !isToday && date.setCurrentDate(nextDay)}
                    >
                        {days[nextDay.getDay()]}
                    </span>
                )
            })}
        </div>
    )
};

export default NextDays;
