import React from 'react';
import {Popup} from "semantic-ui-react";

import {SPORTS_ICON, SPORTS_NAME} from "../../../../../const/sports";

const EventSportIcon = ({event}) => (
    <div className={'sc__event-item__icons flex flex-1 justify-end'}>
        <Popup
            trigger={<i className={SPORTS_ICON[event.sport_id]}></i>}
            content={<>{SPORTS_NAME[event.sport_id] ?? ''}</>}
            size='tiny'
        />
    </div>
);

export default EventSportIcon;
