import React, {useState} from "react";
import classNames from "classnames";
import {Popup} from "semantic-ui-react";

import ModalLayout from "../../modal/ModalLayout";
import EventPopup from "./EventPopup";
import EventHeader from "./common/EventHeader";
import EventFooter from "./common/EventFooter";

import {getEventPositionStyle} from "./utils/position";

import {EVENT_STATUS_AVAILABLE, EVENT_STATUS_LIVE, EVENT_STATUS_SCHEDULED} from "../../../const/eventStatus";
import {EVENT_TYPE_SCHEDULED} from "../../../const/eventType";

import useScheduleContext from "../../../context/useScheduleContext";
import {isEventInFilters} from "./utils/filterEvents";

const Event = ({event}) => {
    const {filters} = useScheduleContext(),
        [openModal, setOpenModalModal] = useState(false),
        //@TODO what about events between I don't know - 60px - 120 px?
        isFullEvent = (event.view.height > 60) ? true : false,
        isInFilters = isEventInFilters(event, filters.filtersSelected),
        isFiltering = Object.keys(filters.filtersSelected).length && event.event_type === EVENT_TYPE_SCHEDULED;

    const eventClassName = classNames('sc__event-item sr-gray-700 flex flex-col box-border flex-1 max-w-full w-full h-full scheduled',
        {
            'tiny-item-height': event.view.height < 40,
            'small-item-height': event.view.height < 90,
            // Event status
            'live': event.status_id === EVENT_STATUS_LIVE,
            'scheduled': event.status_id === EVENT_STATUS_SCHEDULED,
            'available': event.status_id === EVENT_STATUS_AVAILABLE,
            // Filter - Active / inactive
            'filter-active': isFiltering && isInFilters,
            'filter-inactive': isFiltering && !isInFilters,
        }
    );

    return (
        <>
            {/* @TODO Lukasz remove EventModal by global Modal component */}
            <ModalLayout open={openModal} event={event} setOpen={setOpenModalModal}/>
            <Popup
                disabled={isFullEvent}
                //open={true}
                trigger={
                    <div className={'col flex flex-1 absolute'} style={getEventPositionStyle(event)}>
                        <div className={eventClassName} onClick={() => setOpenModalModal(true)}>
                            <div className="flex flex-col flex-1">
                                <EventHeader event={event}/>
                                <EventFooter event={event}/>
                            </div>
                        </div>
                    </div>
                }>
                {!isFullEvent && <EventPopup event={event}/>}
            </Popup>
        </>
    );
};

export default Event;
