import React from 'react';

/**
 * Display LAST categories
 */
const LAST_CATEGORIES_NUMBER = 3;

const Categories = ({event, lastCategories = LAST_CATEGORIES_NUMBER}) => {
    const categoriesList = event.category.slice(`-${lastCategories}`),
        categoriesListLength = categoriesList.length;
    let separator = '>';

    return (
        <>
            {categoriesList.map((categoryName, index) => {
                if (index + 1 === categoriesListLength) {
                    separator = '';
                }

                return (<span key={categoryName}>{categoryName} {separator}&nbsp;</span>)
            })}
        </>
    );
}


export default Categories;
