//@TODO Lukasz - create config file and define 25 as config
const calculateWidth = (eventsNumberColumns) => {
    const elementWidth = 100 / eventsNumberColumns;
    // Max 4 elements without scroll
    if (elementWidth <= 25) {
        return 25;
    }

    return elementWidth;
};

//@TODO LUKASZ - method converted JSON to data with position, column number etc.
export const addDisplayingData = (event, eventsNumberColumns) => {
    const timeStart = new Date(event.time_start).getTime(),
        timeEnd = new Date(event.time_end).getTime(),
        height = (timeEnd - timeStart) / 60000,
        width = calculateWidth(eventsNumberColumns),
        dayStartTime = new Date('2021-12-21 00:00').getTime(),
        positionTop = (timeStart - dayStartTime) / 60000,
        positionLeft = ((event.column - 1) * width);

    return Object.assign({}, event, {
        view: {
            timeStart,
            height,
            width,
            dayStartTime,
            positionTop,
            positionLeft
        },
    })
};
