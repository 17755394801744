import {useEffect, useState} from "react";

const useFiltersOptions = () => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (
            async function () {
                try {
                    setLoading(true)

                    setTimeout(function () {
                        setData({
                            sport: [
                                {key: 'Football', text: 'Football', value: 1},
                                {key: 'Basketball', text: 'Basketball', value: 2},
                            ],
                            status: [
                                {key: 'live', text: 'live', value: 1},
                                {key: 'scheduled', text: 'scheduled', value: 2},
                                {key: 'available', text: 'available', value: 3},
                            ],
                            action: [
                                {key: 'highlight', text: 'highlight', value: 1},
                                {key: 'data only', text: 'data only', value: 2},
                                {key: 'stream break', text: 'stream break', value: 3},
                                {key: 'no betting', text: 'no betting', value: 4},
                            ],
                        });
                        setLoading(false);
                        setLoading(false);
                    }, 1000);
                } catch (err) {
                    setError(err)
                }
            }
        )()
    }, [])

    return {data, error, loading}
}

export default useFiltersOptions;
