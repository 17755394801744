import React from 'react';
import {Popup} from "semantic-ui-react";

import {
    EVENT_ACTION_BACKGROUND_CLASSNAMES,
    EVENT_ACTION_ICON_CLASSNAMES,
    EVENT_ACTION_NAMES
} from "../../../../../const/eventAction";

const EventActionIcon = ({event}) => (
    <div className={EVENT_ACTION_BACKGROUND_CLASSNAMES[event.action_id]}>
        <Popup
            trigger={
                <span>
                    <i className={EVENT_ACTION_ICON_CLASSNAMES[event.action_id] ?? ''}></i>
                </span>
            }
            content={EVENT_ACTION_NAMES[event.action_id]}
            size='tiny'
        />
    </div>
);

export default EventActionIcon;
