import {useState} from "react";

const useDate = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    return [
        currentDate,
        setCurrentDate
    ];
}

export default useDate;
