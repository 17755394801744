import React from 'react';

import HeaderLayout from "./components/header/HeaderLayout";
import TimelineLayout from "./components/timeline/TimelineLayout";

import {ScheduleContextProvider} from "./context/ScheduleContext";

const ScheduleLayout = () => (
    <ScheduleContextProvider>
        <HeaderLayout/>
        <TimelineLayout/>
    </ScheduleContextProvider>
);

export default ScheduleLayout;
