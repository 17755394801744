import {useState} from "react";
import {clone as _clone, assign as _assign} from "lodash";

const useFiltersSelected = () => {
    const [filters, _setFilters] = useState({});

    const setFilters = (fieldName, filterValues) => {
        const newFilters = _clone(filters);

        if (filterValues.length) {
            _assign(newFilters, {[fieldName]: filterValues});
        } else {
            delete newFilters[fieldName];
        }

        _setFilters(newFilters);
    }

    return [
        filters,
        setFilters
    ];
}

export default useFiltersSelected;
