import React from 'react';
import {Button, Divider, Icon} from 'semantic-ui-react';

const ButtonGallery = () => (
    <div>
        <Button size='mini'>Mini</Button>
        <Button size='tiny'>Tiny</Button>
        <Button size='small'>Small</Button>
        <Button size='medium'>Medium</Button>
        <Button size='large'>Large</Button>
        <Button size='big'>Big</Button>
        <Button size='huge'>Huge</Button>
        <Button size='massive'>Massive</Button>
        <br/>
        <br/>
        <Button color='red'>Red</Button>
        <Button color='orange'>Orange</Button>
        <Button color='yellow'>Yellow</Button>
        <Button color='olive'>Olive</Button>
        <Button color='green'>Green</Button>
        <Button color='teal'>Teal</Button>
        <Button color='blue'>Blue</Button>
        <Button color='violet'>Violet</Button>
        <Button color='purple'>Purple</Button>
        <Button color='pink'>Pink</Button>
        <Button color='brown'>Brown</Button>
        <Button color='grey'>Grey</Button>
        <Button color='black'>Black</Button>
        <br/>
        <br/>
        <Button circular color='facebook' icon='facebook'/>
        <Button circular color='twitter' icon='twitter'/>
        <Button circular color='linkedin' icon='linkedin'/>
        <Button circular color='google plus' icon='google plus'/>
        <br/>
        <br/>
        <Button attached='left'>Left</Button>
        <Button attached='right'>Right</Button>
        <br/>
        <br/>
        <Button.Group floated='left'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
        </Button.Group>
        <Button.Group floated='right'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
        </Button.Group>
        <br/>
        <br/>
        <Button.Group vertical>
            <Button>Feed</Button>
            <Button>Messages</Button>
            <Button>Events</Button>
            <Button>Photos</Button>
        </Button.Group>
        <br/>
        <br/>
        <Button.Group icon>
            <Button>
                <Icon name='play'/>
            </Button>
            <Button>
                <Icon name='pause'/>
            </Button>
            <Button>
                <Icon name='shuffle'/>
            </Button>
        </Button.Group>
        <br/>
        <br/>
        <Button.Group vertical labeled icon>
            <Button icon='play' content='Play'/>
            <Button icon='pause' content='Pause'/>
            <Button icon='shuffle' content='Shuffle'/>
        </Button.Group>
        <br/>
        <br/>
        <Button.Group widths='5'>
            <Button>Overview</Button>
            <Button>Specs</Button>
            <Button>Warranty</Button>
            <Button>Reviews</Button>
            <Button>Support</Button>
        </Button.Group>
        <Divider/>
        <Button.Group widths='3'>
            <Button>Overview</Button>
            <Button>Specs</Button>
            <Button>Support</Button>
        </Button.Group>
        <br/>
        <br/>
        <Button.Group color='blue'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
        </Button.Group>
    </div>
);

export default ButtonGallery;
