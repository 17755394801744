import React from 'react';
import {Table} from "semantic-ui-react";

const Tab2 = ({event}) => (
    <div>
        <Table celled>
            <Table.Body>
            <Table.Row>
                <Table.Cell>Name:</Table.Cell>
                <Table.Cell>
                    <a href={`#sport`}>
                        {event.sport_id} {event.name}
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Aspect ratio:</Table.Cell>
                <Table.Cell>16:9</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Entry Point</Table.Cell>
                <Table.Cell>
                    <a href={`#wowza`}>
                        New Wowza Setup in Zurich
                    </a>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Streaming platform</Table.Cell>
                <Table.Cell>Limelight</Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
    </div>
);

export default Tab2;
