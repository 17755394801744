import React from 'react';
import EventCategories from "./footer/EventCategories";
import EventActionIcon from "./footer/EventActionIcon";

const EventFooter = ({event}) => (
    <div className={'sc__event-item__bottom-row flex justify-between items-end flex-nowrap'}>
        <div className="flex items-center justify-between flex-1 max-w-full">
            <EventCategories event={event}/>
            <EventActionIcon event={event}/>
        </div>
    </div>
);

export default EventFooter;
