import React from 'react';
import EventTime from "./header/EventTime";
import EventType from "./header/EventType";
import EventSportIcon from "./header/EventSportIcon";
import EventName from "./EventName";

const EventHeader = ({event}) => (
    <>
        <div className={'sc__event-item__top-row flex'}>
            <EventTime event={event}/>
            <EventType event={event}/>
            <EventSportIcon event={event}/>
        </div>
        <EventName event={event}/>
    </>
);

export default EventHeader;
