import React from 'react';
import {Popup} from "semantic-ui-react";
import Categories from "./Categories";

const EventCategories = ({event}) => (
    <div className={'sc__event-item__meta flex items-center flex-nowrap flex-1 width-70-per'}>
        <Popup
            trigger={
                <div>
                    <i className={`icon-012-information-circle--core--interface--alert-`}></i>
                </div>
            }
            content={
                <div>
                    {/* @TODO Babsi - add ">" in the categories list (i) icon*/}
                    <Categories event={event} lastCategories={0}/>
                </div>
            }
            size='tiny'
        />
        <Categories event={event}/>
    </div>
);

export default EventCategories;
