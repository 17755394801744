import React from 'react';
import useScheduleContext from "../../context/useScheduleContext";
import {getYearMonthDay} from "../../utils/time";
import TimelineFilters from "./TimelineFilters";

const TimelineHeader = () => {
    const {date} = useScheduleContext(),
        calendarDateString = getYearMonthDay(date.currentDate),
        currentDateString = (getYearMonthDay(new Date()) === calendarDateString) ? 'today' : calendarDateString;

    return (
        <div className='sticky-header'>
            <div id="sc__filter-block">
                <TimelineFilters />
            </div>
            <div className={'sc__fixed-header flex flex-col z-02'}>
                <div className={'sc__column__title flex box-border'}>
                    <div className={'sc__column flex box-border justify-center'}>
                        <div className={'sc__fixed-header__title'}>
                            <h3>Scheduled events - {currentDateString}</h3>
                        </div>
                    </div>
                    <div className={'sc__column flex box-border justify-center'}>
                        <div className={'sc__fixed-header__title'}>
                            <h3>Available matches - {currentDateString}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimelineHeader;
