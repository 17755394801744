import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Divider} from "semantic-ui-react";

import 'semantic-ui-less/semantic.less'
import './styles/lcr/style.less';
import './styles/lcr/colors.less';
import './styles/lcr/spacing.less';
import './styles/lcr/schedule.less';
import './styles/icons.less';
import 'semantic-ui-less/semantic.less'


import routesConfig from "./utils/menu";

function App() {
    return (
        <Router>
            <div>
                <div style={{'paddingTop': '10px', 'textAlign': 'center'}}>
                    {routesConfig.map((route, i) => (
                        <span key={route.path} style={{'padding': '10px'}}>
                            <Link to={route.path}>{route.label}</Link>
                        </span>
                    ))}
                </div>
                <Divider/>
                <Switch>
                    {routesConfig.map((route, i) => (
                        <Route
                            key={route.path}
                            exact={true}
                            path={route.path}
                            render={props => (
                                // pass the sub-routes down to keep nesting
                                <route.component {...props} />
                            )}
                        />
                    ))}
                </Switch>
            </div>
        </Router>
    );
}

export default App;
