import React from 'react';

import useScheduleContext from "../../../context/useScheduleContext";
import EventsList from "./EventsList";

const EventsScheduled = () => {
    const {events} = useScheduleContext();

    // No logic for the left part of scheduling so far
    return (
        <EventsList events={events.scheduled}/>
    )
};

export default EventsScheduled;