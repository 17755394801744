import React, {forwardRef} from 'react';
import DatePicker from "react-datepicker";

import NextDays from "./NextDays";

import {getYearMonthDay} from "../../../utils/time";

import useScheduleContext from "../../../context/useScheduleContext";

const calculateDate = (date, number) => {
    const currentDate = new Date(date);

    currentDate.setDate(currentDate.getDate() + number);

    return currentDate;
};

const DateControl = () => {
    const {date} = useScheduleContext(),
        CurrentDate = forwardRef(({value, onClick}, ref) => (
            <button className={'ui icon button'} onClick={onClick} ref={ref}>
                <i aria-hidden="true" className="calendar alternate outline icon"></i>
            </button>
        ));


    return (
        <div className={'datepicker flex'}>
            <div className={'datepicker__date flex'}>
                <button className={'ui icon button'}
                        onClick={() => date.setCurrentDate(currentDate => calculateDate(currentDate, -1))}>
                    <i aria-hidden="true" className="chevron left icon"></i></button>
                <span className="font-bold mx-8">{getYearMonthDay(date.currentDate)}</span>
                <button className={'ui icon button'}
                        onClick={() => date.setCurrentDate(currentDate => calculateDate(currentDate, 1))}>
                    <i aria-hidden="true" className="chevron right icon"></i></button>
                <DatePicker
                    calendarStartDay={1}
                    customInput={<CurrentDate/>}
                    dateFormat="dd-MM-yyyy"
                    onChange={(dateCalendar) => date.setCurrentDate(dateCalendar)}
                    selected={date.currentDate}
                    popperPlacement="top-start"
                    portalId="root-portal"
                />
            </div>
            <NextDays scheduleDate={date.currentDate} setScheduleDate={date.setCurrentDate}/>
        </div>
    );
};

export default DateControl;
