export const EVENT_ACTION_ICON_CLASSNAMES = {
    1: 'icon-226-fire-hot--core--misc-',
    2: 'icon-576-video-stream-off--media-',
    3: 'icon-176-unlink--interface-',
    4: 'icon-566-coins-money--core--interface--misc-',
};

export const EVENT_ACTION_BACKGROUND_CLASSNAMES = {
    1: 'sc-event-highlight sc__event-item__icons colored-bg',
    2: 'sc-event-data-only sc__event-item__icons colored-bg',
    3: 'sc-event-stream-break sc__event-item__icons colored-bg',
    4: 'sc-event-no-betting sc__event-item__icons colored-bg',
};

export const EVENT_ACTION_NAMES = {
    1: 'highlight',
    2: 'data only',
    3: 'stream break',
    4: 'no betting',
};
