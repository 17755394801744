import React, {useEffect, useRef, useState} from "react";

import {getTime} from '../../utils/time';
import useScheduleContext from "../../context/useScheduleContext";

const TimelineClock = () => {
    const {date} = useScheduleContext(),
        clockRef = useRef(null),
        [, updateState] = useState(),
        dateToday = new Date(),
        getClockPosition = (dateToday) => {
            return dateToday.getHours() * 60 + dateToday.getMinutes()
        },
        currentMonthDay = date.currentDate.getDate();

    useEffect(() => {
        clockRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, [currentMonthDay]);

    useEffect(() => {
        updateState({});

        clockRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});

        const interval = setInterval(() => {
            updateState({});
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="sc-timeline-marker z-01 flex items-center"
             style={{
                 width: '100%',
                 position: 'absolute',
                 'top': getClockPosition(dateToday)
             }}
             ref={clockRef}
        >
            <span className="sc-tooltip">{getTime(dateToday)}</span>
        </div>
    )
}

export default TimelineClock;
