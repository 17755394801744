import ButtonGallery from "../components/ButtonGallery";
import ContainerGallery from "../components/ContainerGallery";
import DividerGallery from "../components/DividerGallery";
import HeaderGallery from "../components/HeaderGallery";
import IconGallery from "../components/IconGallery";
import ImageGallery from "../components/ImageGallery";
import InputGallery from "../components/InputGallery";
import LabelGallery from "../components/LabelGallery";
import ListGallery from "../components/ListGallery";
import LoaderGallery from "../components/LoaderGallery";
import PlaceholderGallery from "../components/PlaceholderGallery";
import RevealGallery from "../components/RevealGallery";
import RailGallery from "../components/RailGallery";
import SegmentGallery from "../components/SegmentGallery";
import StepGallery from "../components/StepGallery";
import Footer from "../components/Footer";
import ScheduleBackup from "../components/ScheduleBackup";
import ScheduleLayout from "../modules/schedule/ScheduleLayout";
import LiveControlLayoutWrapper from "../modules/livecontrol/LiveControlLayout";
import LiveControlChannelWrapper from "../modules/livecontrol/LiveControlChannel";

const routesConfig = [
    {
        label: 'Button',
        path: "/button",
        component: ButtonGallery,
    },
    {
        label: 'Container',
        path: "/container",
        component: ContainerGallery,
    },
    {
        label: 'Divider',
        path: "/divider",
        component: DividerGallery,
    },
    {
        label: 'Header',
        path: "/header",
        component: HeaderGallery,
    },
    {
        label: 'Icon',
        path: "/icon",
        component: IconGallery,
    },
    {
        label: 'Image',
        path: "/image",
        component: ImageGallery,
    },
    {
        label: 'Input',
        path: "/input",
        component: InputGallery,
    },
    {
        label: 'Label',
        path: "/label",
        component: LabelGallery,
    },
    {
        label: 'List',
        path: "/list",
        component: ListGallery,
    },
    {
        label: 'Loader',
        path: "/loader",
        component: LoaderGallery,
    },
    {
        label: 'Placeholder',
        path: "/placeholder",
        component: PlaceholderGallery,
    },
    {
        label: 'Rail',
        path: "/rail",
        component: RailGallery,
    },
    {
        label: 'Reveal',
        path: "/reveal",
        component: RevealGallery,
    },
    {
        label: 'Segment',
        path: "/segment",
        component: SegmentGallery,
    },
    {
        label: 'Step',
        path: "/step",
        component: StepGallery,
    },
    {
        label: 'Footer',
        path: "/footer",
        component: Footer,
    },
    {
        label: 'Schedule backup',
        path: "/schedule-backup",
        component: ScheduleBackup,
    },
    {
        label: 'Schedule',
        path: "/schedule",
        component: ScheduleLayout,
    },
    {
        label: 'Live Control',
        path: "/live-control",
        component: LiveControlLayoutWrapper,
    },
    {
        label: 'Live Control Channel',
        path: "/live-control-channel",
        component: LiveControlChannelWrapper,
    },
];

export default routesConfig;