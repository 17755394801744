import React, {useMemo} from "react";
import {each as _each, get as _get} from "lodash";

import EventsList from "./EventsList";
import useScheduleContext from "../../../context/useScheduleContext";
import {getEventsListByFilters} from "./utils/filterEvents";

const EventsAvailable = () => {
    const {filters, events} = useScheduleContext(),
        eventsAvailable = _get(events, 'available', []);

    /**
     * events count and filters selected count
     */
    let impactfulElementsCount = eventsAvailable.length;

    _each(filters.filtersSelected, (filterValues) => {
        impactfulElementsCount += filterValues.length
    });

    /**
     * @TODO Lukasz
     * useMemo should work on filters + events (maybe flag after refetching with time in context to avoid refiltering?)
     * in case if number of events are the same, but events are different !!!
     */
    const eventsAvailableFiltered = useMemo(() =>
            getEventsListByFilters(
                eventsAvailable,
                filters.filtersSelected
            )
        , [impactfulElementsCount]
    );

    return (
        <EventsList events={eventsAvailableFiltered}/>
    );
};

export default EventsAvailable;
