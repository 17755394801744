import React from "react";

import {
    EVENT_ACTION_BACKGROUND_CLASSNAMES,
    EVENT_ACTION_ICON_CLASSNAMES,
    EVENT_ACTION_NAMES
} from "../../../const/eventAction";

const ActionLegend = () => (
    <div className="sc__legend flex sr-gray-1000">
        {Object.keys(EVENT_ACTION_BACKGROUND_CLASSNAMES).map(actionId => (
            <div key={actionId} className="sc__legend__action flex items-center">
                <div className={`${EVENT_ACTION_BACKGROUND_CLASSNAMES[actionId]} flex`}>
                    <i className={EVENT_ACTION_ICON_CLASSNAMES[actionId]}></i>
                </div>
                <span>{EVENT_ACTION_NAMES[actionId]}</span>
            </div>
        ))}
    </div>
);

export default ActionLegend;
