import React from 'react';

const EventType = ({event}) => (
    <div className={'sc__event-item__status sr-red-700 items-center'}>
        <i className="circle icon tiny red"></i>
        <span>{event.type}</span>
    </div>
);

export default EventType;
